<template>
    <div>
        <b-row>
            <b-col>
                <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :columns="header" :rows="columnData" @on-row-click="onRowClick" :total-rows="pageTotal" :per-page="serverParams.perPage" :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                }">
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="serverParams.perPage" :options="[5, 10, 20, 50, 100]" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>

                            <b-pagination :value="1" :total-rows="pageTotal" :per-page="serverParams.perPage" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </template>
                </vue-good-table>
            </b-col>
        </b-row>
        <b-modal id="customerDetails-modal" title="Customer Details" ok-title="Ok" cancel-title="Cancel" centered>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0">Name:</h6>
                    <span>{{ modal.name }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0 mt-2">Vehicle Model & Variant:</h6>
                    <span>{{ modal.model }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0 mt-2">Phone Number:</h6>
                    <span>{{ modal.mobileNo }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0 mt-2">Email Address:</h6>
                    <span>{{ modal.email }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0 mt-2">Expire/Renewal Date:</h6>
                    <span>{{ $date(modal.expDate, "DDMMYYYY").format("DD/MM/YYYY") }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="text-capitalize mb-0 mt-2">
                        <font-awesome-icon icon="share" size="1x" />Renewal reminder:
                    </h6>
                    <div class="mr-0">
                        <font-awesome-icon icon="sms" size="2x" class="p-1 icons cursor-pointer" @click="smsHandler" />
                        <font-awesome-icon :icon="['fab', 'whatsapp']" size="2x" class="p-1 icons cursor-pointer" @click="whatsappHandler" />
                        <font-awesome-icon icon="envelope" size="2x" class="p-1 icons cursor-pointer" @click="emailHandler" />
                        <font-awesome-icon icon="copy" size="2x" class="p-1 icons cursor-pointer" @click="copyHandler" />
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { getCustomers, getShareLink } from "@/services/AgentService";
import { BCard, BCardText, BCol, BRow, BCardBody, BButton, BModal, BPagination, BFormSelect, BBadge } from 'bootstrap-vue';
import { VueGoodTable } from "vue-good-table";
export default {
    name: "Customers",
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BCardBody,
        BButton,
        BModal,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge
    },
    data() {
        return {
            isLoading: false,
            pageTotal: 0,
            columnData: [{}],
            modal: {},
            serverParams: {
                columnFilters: {},
                sort: [
                    {
                        field: 'createdAt',
                        type: 'desc'
                    }

                ],
                page: 1,
                perPage: 10
            }
        };
    },
    async mounted() {
        await this.loadItems();
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
        header() {
            return [
                {
                    label: "Expire",
                    field: "expDate",
                    type: "date",
                    dateInputFormat: "ddMMyyyy",
                    dateOutputFormat: "dd/MM/yyyy",
                },
                {
                    label: "Plate No.",
                    field: "vehNo",
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "Last Trans",
                    field: "lastTrans",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
                    dateOutputFormat: "dd/MM/yyyy",
                },
                {
                    label: "Past Paid",
                    field: "lastPaid",
                },
                {
                    label: "Policy Type",
                    field: "policyType",
                    hidden: this.isMobile,
                },
                {
                    label: "Vehice Type",
                    field: "vehClass",
                    formatFn: (e) => e === "01" ? "Motorcycle" : "Car",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true,
                        filterDropdownItems: [
                            {
                                text: "Motorcycle",
                                value: "01",
                            },
                            {
                                text: "Car",
                                value: "02",
                            }
                        ]
                    }
                },
                {
                    label: "Model",
                    field: "model",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "CC",
                    field: "cc",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "Year Make",
                    field: "yearMake",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "NCD %",
                    field: "ncd",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "Source",
                    field: "source",
                    hidden: this.isMobile,
                    filterOptions: {
                        enabled: true,
                        filterDropdownItems: [
                            {
                                text: "Web",
                                value: "Web",
                            },
                            {
                                text: "Customer",
                                value: "Customer"
                            },
                            {
                                text: "Agent",
                                value: "Agent"
                            }
                        ]
                    }
                },
            ];
        },
    },
    methods: {
        onRowClick(e) {
            this.modal = e.row;
            this.$bvModal.show("customerDetails-modal");
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        async onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            await this.loadItems();
        },
        async onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            await this.loadItems();
        },
        async onSortChange(params) {
            this.updateParams({
                sort: params
            });
            await this.loadItems();
        },
        async onColumnFilter(params) {
            this.updateParams(params);
            await this.loadItems();
        },
        async loadItems() {
            this.isLoading = true;

            const skip = (this.serverParams.page - 1) * this.serverParams.perPage;
            const take = this.serverParams.perPage;
            const filter = this.serverParams.columnFilters;
            const sort = this.serverParams.sort;
            const { customers, totalCount } = await getCustomers(skip, take, sort, filter);
            this.columnData = customers;
            this.pageTotal = totalCount;
            this.isLoading = false;
        },
        onRowClick(e) {
            this.modal = e.row;
            this.$bvModal.show("customerDetails-modal");
        },
        async whatsappHandler() {
            const link = await getShareLink(this.modal.id, false, true);
            const whatsappLink = `https://api.whatsapp.com/send?phone=${this.modal.mobileNo.replace("+", "")}&text=${link}`;
            window.open(whatsappLink, "_NEW");
        },
        async smsHandler() {
            const link = await getShareLink(this.modal.id, false);
            const smsLink = `sms:${this.modal.mobileNo}?body=${link}`;
            window.open(smsLink, "_NEW");
        },
        async copyHandler() {
            try {
                const link = await getShareLink(this.modal.id, false);
                const copyLink = link.replaceAll("%0a", "\r\n");

                await this.$copyText(copyLink);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Text copied",
                        icon: "CopyIcon",
                        variant: "success",
                    },
                });
            } catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Can not copy!",
                        icon: "CopyIcon",
                        variant: "warning",
                    },
                });
            }
        },
        async emailHandler() {
            const link = await getShareLink(this.modal.id, false);

            let emailLink;
            if (this.isMobile) emailLink = `mailto:${this.modal.email}?subject=${this.modal.vehNo}%20Instant%20Renewal%20&%20quotation%20link&body=${link}`;
            else emailLink = `https://mail.google.com/mail/u/0/?fs=1&to=${this.modal.email}&su=${this.modal.vehNo}%20instant%20renewal%20&%20quotation%20link&body=${link}&tf=cm`;
            window.open(emailLink, "_NEW");
        },
    },
};
</script>
